import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutSuperAdminComponent implements OnInit {
  leftpart = true;
  userarea = true;
  constructor(private toastr: ToastrService,
    private router: Router ,) { }
  ngOnInit() {
    $('.menuExtande.open').click(function () {
      $('.leftpart').toggleClass('leftpartcollapes');   
      $('.menuExtande.open i').toggleClass('fa-xmark')
    })

    $('.navbarbtn').click(function () {
      $('.mainebox').toggleClass('menuopened');
      $('.leftpart').toggleClass('leftpartcollapes');
      $('.menuarea').toggleClass('toggeledmenu')
      $('.customedropdownmenu').removeClass('show');
      // $('.customedropdownmenu').removeAttr('style', 'display');

      
    });
    $('.closemenum, .menuExtande.close').click(function () {
      $('.leftpart').removeClass('leftpartcollapes');
      $('.mainebox').removeClass('menuopened');
      $('.customedropdownmenu').removeClass('show');
      $('.menuarea').removeClass('toggeledmenu')
      $('.menuExtande.open i').removeClass('fa-xmark')
      // $('.customedropdownmenu').removeAttr('style', 'display')

    });


    $('.subMenu').click(function(){
      $('.dropMenu').toggleClass('dropMenuOpened')
    })

    $('.menuarea').click(function(){
      // $('.dropMenu').toggleClass('dropMenuOpened')
      var findmenu:any =  document.querySelectorAll('.customedropdownmenu')
      for (let menu of findmenu){
        console.log(menu.classList.contains('dropMenuOpened'))
        // if(menu.classList.contains('dropMenuOpened')){
        //   $('.dropMenu').removeClass('dropMenuOpened')
        //   console.log('deepak')
        // }
      }
    })

   
  }

  

}
